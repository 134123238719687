import styled from 'styled-components'
import React from 'react'

const NotionBox = styled.div`
  width: 100%;
`

class NotionPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    const pages = [
      '5f41d9bb7f6f450fa37e98d3a744a114',
      '9cf6650b46cf4913960109343cd121e4',
      '69b4e85e266745c79fe72b023688759f',
      '5f3c00b9339141c6b2f72d1f9e3f1cd5',
    ]
    const pageNum = this.props.pageNum
    const notionDocId = this.props.notionDocId

    fetch('https://potion-api.now.sh/html?id=' + notionDocId)
      .then(res => res.text())
      .then(data => {
        this.setState({ data })
      })
      .catch(error => console.log('its okay!', error))
  }

  render() {
    return (
      <NotionBox>
        <div dangerouslySetInnerHTML={{ __html: this.state.data }} />
      </NotionBox>
    )
  }
}

export default NotionPage
