import styled from 'styled-components'
import React from 'react'
import { Button, Text, Box, Card, Flex } from './Base'
import NotionPage from '../components/NotionPage'
import { Tabs, Tab } from '@material-ui/core'

const Ree = styled.div`
  ${({ hidden }) =>
    hidden &&
    `height: '100%'
  `}
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OuterPage = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
`

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <Ree
      role="tabpanel"
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <OuterPage>
          <Page>{children}</Page>
        </OuterPage>
      )}
    </Ree>
  )
}
const tabProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ResourceSection = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90%;
`
const Page = styled(Card)`
  overflow: scroll;
  height: 80vh;
  align-items: center;
  width: 100%;
  padding: 20pt;
`

const Title = styled(Text)`
  font-size: 50pt;
`
const ResourceHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

class CustomizedTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,

      newValue: null,
      value: 0,
    }
  }

  render() {
    const handleChange = (event, newValue) => {
      this.setState({ value: newValue })
    }
    const determineTab = val => {
      if (val == 0) {
        return <NotionPage notionDocId="5f41d9bb7f6f450fa37e98d3a744a114" />
      }
      if (val === 1) {
        return <NotionPage notionDocId="9cf6650b46cf4913960109343cd121e4" />
      }

      if (val == 2) {
        return <NotionPage notionDocId="69b4e85e266745c79fe72b023688759f" />
      }
      if (val == 3) {
        return <NotionPage notionDocId="5f3c00b9339141c6b2f72d1f9e3f1cd5" />
      }
    }
    return (
      <ResourceSection>
        <ResourceHeader>
          <Title>Coding Resources</Title>
          <Tabs
            value={this.state.value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="General" {...tabProps(0)} />
            <Tab label="Node.js" {...tabProps(1)} />
            <Tab label="Javascript" {...tabProps(2)} />
            <Tab label="C++" {...tabProps(3)} />
          </Tabs>
        </ResourceHeader>
        <TabPanel value={this.state.value} index={0}>
          {console.log('tab', this.state.value)}
          {determineTab(this.state.value)}
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          {determineTab(this.state.value)}
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          {determineTab(this.state.value)}
        </TabPanel>
        <TabPanel value={this.state.value} index={3}>
          {determineTab(this.state.value)}
        </TabPanel>
      </ResourceSection>
    )
  }
}

export default CustomizedTabs
