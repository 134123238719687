import React from 'react'
import SEO from '../components/seo'
import '../components/layout'
import { Layout, Container } from '../components/Base'
import CustomizedTabs from '../components/CustomizedTabs'

class IndexPage extends React.Component {
  state = { loading: false, msg: null }
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      display: false,
    }
  }

  render() {
    return (
      <Container>
        <Layout>
          <SEO title="Home | Kelsey Yim" />
          <CustomizedTabs />
        </Layout>
      </Container>
    )
  }
}

export default IndexPage
